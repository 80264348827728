<template>
  <div>
    <KTCard title="Purchased Course List">
      <template v-slot:toolbar>
        <b-button @click="onOpenPage" variant="primary" size="sm"
          ><i class="fa fa-plus"></i> Purchase New Course</b-button
        >
      </template>

      <template v-slot:body>
        <b-row v-if="!!tableSettings">
          <b-col cols="6">
            <b-form-group class="m-2" label-size="sm">
              <template v-if="tableSettings.refresh">
                <b-button size="sm" class="mr-2">
                  <i class="fa fa-sync"></i> Refresh
                </b-button>
              </template>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              class="m-2"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
            >
              <template v-if="tableSettings.filter">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </template>
            </b-form-group>
          </b-col>
        </b-row>

        <b-table
          :per-page="tableSettings.perPage"
          :current-page="tableSettings.currentPage"
          :filter="filter"
          striped
          bordered
          show-empty
          hover
          :items="PurchasedCourseList"
          :fields="fields"
        >
          <template v-slot:cell(actions)="row">
            <b-button-group size="sm">
              <b-button
                @click="onAssignSeating(row.item)"
                variant="info"
                size="sm"
              >
                Assign Seating
              </b-button>
            </b-button-group>
          </template>
          <template v-slot:cell(Allocated_Slots)="row">
            {{ row.item.Allocated_Slots }}/{{
              row.item.Available_Slots + row.item.Allocated_Slots
            }}
          </template>
        </b-table>

        <b-pagination
          v-if="tableSettings.row > tableSettings.perPage"
          class="pl-0"
          v-model="currentPage"
          :total-rows="tableSettings.rows"
          :per-page="tableSettings.perPage"
        ></b-pagination>
      </template>
    </KTCard>

    <assign-trainee-course></assign-trainee-course>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/modules/breadcrumbs.module";
import {
  GET_TRAINEE_USER_LIST,
  DELETE_TRAINEE_USER,
} from "@/core/services/store/modules/clientadmin/trainee.module";

import { validationMixin } from "vuelidate";

import AssignTraineeCourse from "@/view/pages/clientadmin/trainingcourse/modals/AssignTraineeCourseModal";
import SystemHelper from "@/core/services/systemhelper.service";
import { GET_LIST_PURCHASE_COURSE } from "@/core/services/store/modules/clientadmin/course.module";

export default {
  mixins: [validationMixin],
  components: { AssignTraineeCourse },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Client Admin" },
      { title: "Purchased Course" },
    ]);

    this.$store.dispatch(GET_LIST_PURCHASE_COURSE, this.defaultPayload);
  },
  computed: {
    tableSettings() {
      const list =
        this.$store.state.clientadmin_course.companyPurchasedCourseList;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        refresh: false,
        filter: true,
        pagination: true,
      };
    },
    PurchasedCourseList() {
      const list =
        this.$store.state.clientadmin_course.companyPurchasedCourseList;
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
  data() {
    return {
      modalId: "TraineeUserPreRegistrationModal",
      selectedTrainee: null,
      filter: "",
      currentPage: 1,
      fields: [
        {
          key: "Purchase_course.trainee_course_id",
          label: "Course ID",
          sortable: true,
        },
        {
          key: "Purchase_course.course_name",
          label: "Course Name",
          sortable: true,
        },
        {
          key: "Allocated_Slots",
          label: "Allocated/Total Seat",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
          class: "text-right",
        },
      ],
    };
  },

  methods: {
    onOpenModal() {
      this.openModal("modalAddTraineeUser");
    },
    onOpenPage() {
      this.$router.push({ name: "company-course-list" });
    },
    onAssignSeating(course) {
      this.selectedCourse = course;
      this.openModal("modalAssignTraineeCourse");
    },
    onRemove(trainee) {
      // assign selected trainee
      this.selectedTrainee = trainee;

      let payload = {
        trainee_user_id: this.selectedTrainee.trainee_user_id,
      };

      // open permission modal
      SystemHelper.confirmationDialogHandler(
        { title: "Confirmation", html: "Remove User?" },
        { action: DELETE_TRAINEE_USER, payload: payload },
        [{ action: GET_TRAINEE_USER_LIST, payload: this.defaultPayload }],
        null
      );
    },
  },
};
</script>
