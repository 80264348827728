<template>
  <b-modal
    id="modalAssignTraineeCourse"
    :visible="modalVisibility(modalId)"
    title="Assign Course"
    @show="onShow()"
    @hide="resetModal()"
    size="xl"
    @cancel="resetModal()"
    @ok="onSubmit()"
    no-close-on-backdrop
    ok-title="Submit"
  >
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <base-input
              :disabled="readOnly"
              v-model="selectedCourseDetails.course_name"
              label="Course Name"
            ></base-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <base-input
              type="textarea"
              :disabled="readOnly"
              v-model="selectedCourseDetails.description"
              label="Course Description"
            ></base-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <base-input
              :disabled="readOnly"
              v-model="slotAvailable"
              label="Allocated/Total Seat"
            ></base-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="my-5">
              <h5>Selected User</h5>
            </div>
            <div>
              <b-table
                :items="processUserSubmission()"
                striped
                show-empty
                bordered
                hover
                empty-text="Please select trainee users"
                :fields="selectedUserFields"
              ></b-table>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col>
        <div class="my-5">
          <h5>Available Trainee</h5>
        </div>
        <b-table
          sticky-header="500px"
          empty-text="No trainee user available for selection"
          :per-page="tableSettings.perPage"
          :current-page="tableSettings.currentPage"
          :filter="filter"
          striped
          show-empty
          bordered
          hover
          ref="selectableTraineeUserTable"
          selectable
          @row-selected="onRowSelected"
          :items="tableItems"
          :fields="tableFields"
        >
        </b-table>

        <b-pagination
          v-if="tableSettings.row > tableSettings.perPage"
          class="pl-0"
          v-model="currentPage"
          :total-rows="tableSettings.rows"
          :per-page="tableSettings.perPage"
        ></b-pagination>
        <!--        {{ processUserSubmission() }}-->
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { SET_MODAL_STATE } from "@/core/services/store/modules/ui.module";
import { GET_UNASSIGNED_TRAINEE_USER_LIST } from "@/core/services/store/modules/clientadmin/trainee.module";
import {
  ASSIGN_TRAINEE_INTO_COURSE,
  GET_LIST_PURCHASE_COURSE,
} from "@/core/services/store/modules/clientadmin/course.module";
import SystemHelper from "@/core/services/systemhelper.service";
export default {
  data() {
    return {
      modalId: "modalAssignTraineeCourse",
      isBusy: true,
      selectedCourse: {},
      filter: "",
      selectedUser: null,
      currentPage: 1,
      readOnly: true,
      selectedUserFields: [
        { key: "trainee_user_id", label: "ID" },
        { key: "trainee_username", label: "Username" },
        { key: "trainee_fullname", label: "Name" },
      ],
    };
  },
  computed: {
    slotAvailable() {
      let allocatedSeat = this.selectedCourse.Allocated_Slots;
      let seatTotal =
        parseInt(this.selectedCourse.Allocated_Slots) +
        parseInt(this.selectedCourse.Available_Slots);
      return allocatedSeat + "/" + seatTotal;
    },
    selectedCourseDetails() {
      let x = this.selectedCourse.Purchase_course;
      if (x) {
        return x;
      } else {
        return [];
      }
    },
    tableSettings() {
      const list = this.$store.state.clientadmin_trainee.unassignedTraineeList;
      let perPage = 20;
      let listLength;
      if (Array.isArray(list) && list.length) {
        listLength = list.length;
      } else {
        listLength = 100;
      }
      return {
        row: listLength,
        perPage: perPage,
        refresh: false,
        filter: true,
      };
    },
    tableFields() {
      return [
        { key: "trainee_user_id", label: "ID" },
        { key: "trainee_username", label: "Username" },
        { key: "trainee_fullname", label: "Name" },
        { key: "created_at" },
      ];
    },
    tableItems() {
      const list = this.$store.state.clientadmin_trainee.unassignedTraineeList;
      console.log(list);
      if (Array.isArray(list) && list.length) {
        return list;
      } else {
        return [];
      }
    },
  },
  methods: {
    onSubmit() {
      if (this.selectedUser.length < 1) {
        SystemHelper.basicSwalHandler(
          "Validation Error",
          "You have to select at least 1 Trainee User",
          "danger"
        );
        return;
      } else {
        let payload = {
          assign_trainee_users_list: JSON.stringify(
            this.processUserSubmission()
          ),
        };
        SystemHelper.confirmationDialogHandler(
          { title: "Confirmation", html: "Assign Course?" },
          { action: ASSIGN_TRAINEE_INTO_COURSE, payload: payload },
          [
            {
              action: GET_LIST_PURCHASE_COURSE,
              payload: this.defaultPayload,
            },
          ],
          this.modalId
        );
        // this.$store.dispatch(ASSIGN_TRAINEE_INTO_COURSE, payload);
      }
    },
    processUserSubmission() {
      let items = this.selectedUser;
      let tempArray = [];

      console.log(items);

      if (items && items.length) {
        items.forEach((item) => {
          tempArray.push({
            trainee_user_id: item.trainee_user_id,
            trainee_username: item.trainee_username,
            trainee_fullname: item.trainee_fullname,
            purchase_course_id: this.selectedCourseDetails.trainee_course_id,
          });
        });
      }
      return tempArray;
    },
    onRowSelected(items) {
      this.selectedUser = items;

      this.processUserSubmission();
    },
    selectAllRows() {
      this.$refs.selectableTraineeUserTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTraineeUserTable.clearSelected();
    },
    onShow() {
      console.log("showing");
      this.isBusy = true;
      // assign selected trainee
      console.log(this.$parent.selectedCourse);
      this.selectedCourse = this.$parent.selectedCourse;

      this.$store.dispatch(GET_UNASSIGNED_TRAINEE_USER_LIST, {
        trainee_course_id: this.selectedCourse.trainee_course_id,
      });
    },
    resetModal() {
      this.selectedUser = null;
      console.log(SET_MODAL_STATE);
      this.$store.dispatch(SET_MODAL_STATE, null);
    },
  },
};
</script>
